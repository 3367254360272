import React from 'react';

import marked from '../utils/NewTabMarked';
import './RichText.scss';

const RichText = ({ className, text }) => (
	<div className={`richtext ${className}`} dangerouslySetInnerHTML={{ __html: marked(text) }} />
);

export default RichText;
