import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Share from '../../assets/share.svg';
import ProgressBar, { MODE } from '../progressbar/ProgressBar';
import SharePopup from '../sharepopup/SharePopup';
import './StoryInfo.scss';

const StoryInfo = ({
	story,
	isActive,
	isPlaying,
	storyIndex,
	storyCount,
	audioEl,
	onClickIndex,
	onClickProgress
}) => {
	const progressBarRef = useRef();

	const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
	const durationRef = useRef();

	useEffect(() => {
		const onUpdateTime = () => {
			if (isPlaying) {
				const time = audioEl.currentTime;
				const progress = (time / audioEl.duration) * 100;
				progressBarRef.current.style.width = `${progress}%`;
				durationRef.current.innerHTML = `${`00${(time / 60) | 0}`.slice(-2)}:${`00${(time |
					0) %
					60}`.slice(-2)}`;
			}
		};
		progressBarRef.current.style.width = '0%';
		if (audioEl) audioEl.addEventListener('timeupdate', onUpdateTime);

		return () => {
			if (audioEl) audioEl.removeEventListener('timeupdate', onUpdateTime);
		};
	}, [audioEl, isPlaying]);

	useEffect(() => {
		durationRef.current.innerHTML = '';
	}, [isPlaying]);

	const classes = classNames({
		'story-info': true,
		'story-info--hidden': !story,
		'story-info--active': isActive
	});

	return (
		<div className={classes}>
			<div className="story-info__center">
				<div className="story-info__progress">
					<ProgressBar
						mode={isActive ? MODE.CONTINUOUS : MODE.STEPS}
						progress={storyIndex / storyCount}
						stepCount={storyCount}
						barRef={progressBarRef}
						onClickIndex={onClickIndex}
						onClickProgress={onClickProgress}
					/>
					<div className="story-info__duration" ref={durationRef} />
				</div>
				<SharePopup isOpen={isSharePopupOpen} onClose={() => setIsSharePopupOpen(false)} />
			</div>
			<div className="story-info__share" onClick={() => setIsSharePopupOpen(true)}>
				<Share />
			</div>
		</div>
	);
};

export default StoryInfo;
