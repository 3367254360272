import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './Menu.scss';
import MenuPage from './MenuPage';

const Menu = ({ page, country }) => {
	const [openedMenuItem, setOpenedMenuItem] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const panelClasses = classNames({
		menu__panel: true,
		'menu__panel--open': isOpen,
		'menu__panel--show-page': openedMenuItem
	});
	const hamburgerClasses = classNames({
		menu__hamburger: true,
		'menu__hamburger--open': isOpen
	});

	useEffect(() => setIsOpen(false), [page]); //* Close menu when page changes.

	const onClose = () => {
		if (!openedMenuItem) {
			setIsOpen(!isOpen);
		}
		setOpenedMenuItem(null);
	};

	const { menuItems } = country;
	const hasContent = menuItems && menuItems.length;

	return hasContent ? (
		<div className="menu">
			<div className={panelClasses}>
				<ul className="menu__list">
					{menuItems.map(menuItem => {
						const { label } = menuItem.fields;
						return label ? (
							<li
								key={label}
								className="menu__item"
								onClick={() => setOpenedMenuItem(menuItem)}
							>
								{label}
							</li>
						) : null;
					})}
				</ul>
				<div className="menu__page">
					<MenuPage
						id={openedMenuItem && openedMenuItem.sys.id}
						content={openedMenuItem && openedMenuItem.fields.content}
					/>
				</div>
			</div>
			<div className="menu__button" onClick={onClose}>
				<div className={hamburgerClasses} />
			</div>
		</div>
	) : null;
};

export default Menu;
