import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';

function configureRouter() {
	const router = createRouter(
		[
			{
				name: 'start',
				path: '/'
			},
			{
				name: 'story',
				path: '/:storyId'
			},
			{
				name: 'slug',
				path: '/:storyId/:storySlug'
			}
		],
		{
			trailingSlashMode: 'always',
			defaultRoute: 'start',
			queryParamsMode: 'loose'
		}
	);
	router.usePlugin(
		browserPlugin({
			useHash: false
		})
	);

	return router;
}

export default configureRouter;
