import classnames from 'classnames';
import React from 'react';
import SwipeLeft from '../assets/swipe-left.svg';
import './Landing.scss';

const Landing = ({ onClick, country }) => (
	<div
		className={classnames({
			landing: true,
			'landing--loaded': !!country
		})}
	>
		{country && (
			<>
				<div className="landing__swipe" onClick={onClick}>
					<SwipeLeft />
				</div>
				{country.landingPageTagline && (
					<div className="landing__tagline">{country.landingPageTagline}</div>
				)}
			</>
		)}
	</div>
);

export default Landing;
