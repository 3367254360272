import React from 'react';
import './Introduction.scss';
import RichText from './RichText';

const Introduction = ({ country }) => (
	<div className="introduction">
		<div className="introduction__content">
			<RichText className="introduction__header" text={country ? country.header : ''} />
			<RichText className="introduction__body" text={country ? country.introduction : ''} />
		</div>
	</div>
);

export default Introduction;
