import React from 'react';
import './Photo.scss';

const sizes = [640, 800, 1200, 1600];
const Photo = ({ story }) => {
	if (!story.image) return null;
	const { url } = story.image.fields.file;
	const srcSet = sizes.map(size => `${url}?w=${size} ${size}w`).join(', ');
	return (
		<div className="photo">
			<picture>
				<source srcSet={srcSet} />
				<img className="photo__image" src={`${url}?w=800`} alt="" />
			</picture>
		</div>
	);
};

export default Photo;
