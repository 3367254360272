const WINDOW_FEATURES = 'width=600,height=500';
const TWITTER_BASE_URL = 'https://twitter.com/intent/tweet';
// const LINKEDIN_BASE_URL = 'https://www.linkedin.com/shareArticle';
const FACEBOOK_BASE_URL = 'https://www.facebook.com/sharer/sharer.php';
const TELEGRAM_BASE_URL = 'https://t.me/share/url';

export const SOCIAL_MEDIA = {
	facebook: 'Facebook',
	twitter: 'Twitter',
	// linkedin: 'LinkedIn', //! dont use anymore: https://stackoverflow.com/a/58118474/4324040
	email: 'Email',
	whatsapp: 'Whatsapp',
	telegram: 'Telegram',
	copyurl: 'Copy URL'
};

const openPopup = (url, close = false) => {
	const popup = window.open(url, 'popup', WINDOW_FEATURES);
	if (window.focus) {
		popup.focus();
	}
	if (close) {
		setTimeout(() => popup.close(), 1000);
	}
};

const copyToClipboard = text => {
	const el = document.createElement('textarea'); // Create a <textarea> element
	el.value = text; // Set its value to the string that you want copied
	el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
	el.style.display = 'absolute';
	el.style.left = '-9999px'; // Move outside the screen to make it invisible
	document.body.appendChild(el); // Append the <textarea> element to the HTML document
	const previousSelection =
		document.getSelection().rangeCount > 0 // Check if there is any content selected previously
			? document.getSelection().getRangeAt(0) // Store selection if found
			: false; // Mark as false to know no selection existed before
	el.select(); // Select the <textarea> content
	document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
	document.body.removeChild(el); // Remove the <textarea> element
	if (previousSelection) {
		// If a selection existed before copying
		document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
		document.getSelection().addRange(previousSelection); // Restore the original selection
	}
};

export const openSharePopup = (socialTitle, title, hashtags, message) => {
	const currentUrl = window.location.href;
	let shareUrl;
	switch (socialTitle) {
		case SOCIAL_MEDIA.facebook:
			shareUrl = `${FACEBOOK_BASE_URL}?u=${encodeURIComponent(
				currentUrl
			)}&title=${encodeURIComponent(title)}&quote=${encodeURIComponent(message)}`;
			break;
		case SOCIAL_MEDIA.twitter:
			shareUrl = `${TWITTER_BASE_URL}?text=${encodeURIComponent(
				`${title.trim()}${message ? `\n\n${message.trim()}` : ''}\n`
			)}&url=${encodeURIComponent(currentUrl)}&hashtags=${encodeURIComponent(hashtags)}`;
			break;
		case SOCIAL_MEDIA.whatsapp:
			shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`;
			break;
		case SOCIAL_MEDIA.email:
			shareUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
				`${message}\n\n${title}\n${currentUrl}`
			)}`;
			break;
		case SOCIAL_MEDIA.telegram:
			shareUrl = `${TELEGRAM_BASE_URL}?url=${encodeURIComponent(
				currentUrl
			)}&text=${encodeURIComponent(title)}`;
			break;
		case SOCIAL_MEDIA.copyurl:
			copyToClipboard(currentUrl);
			window.alert(`copied url:${currentUrl}`);
			return;
		default:
			break;
	}
	openPopup(shareUrl);
};
