import React from 'react';

import './ProgressBar.scss';

export const MODE = {
	STEPS: 'steps',
	CONTINUOUS: 'continuous'
};
const ProgressBar = ({
	progress = 0.0,
	mode = MODE.STEPS,
	stepCount = 10,
	barRef,
	onClickIndex,
	onClickProgress
}) => {
	const steps = new Array(stepCount).fill(0);
	const onTap = e => {
		const percentage = e.nativeEvent.offsetX / e.currentTarget.offsetWidth;
		onClickProgress(percentage);
	};
	return (
		<div className="progress-bar">
			<div className={`progress-bar__bar progress-bar__bar--${mode}`} ref={barRef} />
			{steps.map((step, i) => (
				<div
					key={i}
					className={`progress-bar__step progress-bar__step--mode-${mode} ${
						i <= progress * stepCount ? 'progress-bar__step--passed' : ''
					}`}
					onClick={mode === MODE.STEPS ? () => onClickIndex(i) : undefined}
				/>
			))}
			{mode === MODE.CONTINUOUS && <div className="progress-bar__tap-area" onClick={onTap} />}
		</div>
	);
};

export default ProgressBar;
