import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteNode, useRouter } from 'react-router5';
import urlSlug from 'url-slug';
import { useCountry } from '../../hooks/useContentful';
import Card from '../card/Card';
import Introduction from '../Introduction';
import Landing from '../Landing';
import Logo from '../Logo';
import Menu from '../menu/Menu';
import StoryInfo from '../storyinfo/StoryInfo';
import Swiper from '../swiper/Swiper';
import './App.scss';

const getPageFromRoute = (route, stories) => {
	if (!stories || !route.params.storyId) return 0;

	const storyIndexFromURL = stories.findIndex(
		story => story.id.substr(0, 3) === route.params.storyId.substr(0, 3)
	);
	return storyIndexFromURL >= 0 ? 2 + storyIndexFromURL : 0;
};

const App = ({ data }) => {
	const { route } = useRouteNode('');
	const router = useRouter();

	const [country, isLoaded] = useCountry(data && data.country);
	const stories = country ? country.stories : [];
	const [page, setPage] = useState(getPageFromRoute(route, stories));
	const [isImmediateTransition, setIsImmediateTransition] = useState(true);

	const storyIndex = page - 2;
	const selectedStory = page >= 2 ? stories[storyIndex] : null;

	const [activatedStory, setActivatedStory] = useState();
	const [playingStory, setPlayingStory] = useState();
	const audioRefs = useRef({});

	useEffect(() => {
		setIsImmediateTransition(true);
		setPage(getPageFromRoute(route, stories));
	}, [stories]);

	useEffect(() => {
		let wasPlaying;
		function handleVisibilityChange() {
			if (document.hidden) {
				wasPlaying = !!playingStory;
				if (playingStory) {
					audioRefs.current[playingStory.id].pause();
				}
			} else if (wasPlaying && playingStory) {
				audioRefs.current[playingStory.id].play();
			}
		}

		document.addEventListener('visibilitychange', handleVisibilityChange, false);
		return () =>
			document.removeEventListener('visibilitychange', handleVisibilityChange, false);
	}, [playingStory, activatedStory]);

	// eslint-disable-next-line camelcase
	const track = (eventName, event_category) => {
		if (window && window.gtag) {
			window.gtag('event', eventName, { event_category });
		}
	};

	const onChangePage = newPage => {
		if (newPage >= 2) {
			const storyId = stories[newPage - 2].id.slice(0, 3);
			const storySlug = urlSlug(stories[newPage - 2].title);
			router.navigate('slug', { storyId, storySlug });
			track(storySlug, 'story');
		} else {
			router.navigate('start');
			track('start', 'page');
		}
		setIsImmediateTransition(false);
		setPage(newPage);
		if (playingStory) {
			audioRefs.current[playingStory.id].pause();
			setActivatedStory(null);
			setPlayingStory(null);
		}
	};

	const onClickStory = story => {
		if (story === selectedStory) {
			const nextStory = activatedStory ? null : selectedStory;
			if (!activatedStory) {
				const el = audioRefs.current[selectedStory.id];
				el.play();
				setPlayingStory(nextStory);
			}
			setActivatedStory(nextStory);
		} else {
			const storyPage = stories.findIndex(s => s.id === story.id) + 2;
			onChangePage(storyPage);
		}
	};

	const onClickStart = () => {
		if (playingStory) {
			if (activatedStory) {
				setActivatedStory(null);
			}
			audioRefs.current[playingStory.id].pause();
			setPlayingStory(null);
		} else {
			onClickStory(selectedStory);
		}
	};

	const title = `${country && country.name} ${selectedStory ? ` | ${selectedStory.title}` : ''}`;
	const selectedStoryImage = selectedStory?.image?.fields?.file;
	return (
		<div
			className={classNames({
				app: true,
				'app--rtl': country && country.languageRightToLeft,
				[`app--page-${page}`]: true,
				'app--active': selectedStory && selectedStory === activatedStory
			})}
		>
			<Helmet>
				<title>{title}</title>
				<meta name="theme-color" content="#DB9929" />
				{selectedStoryImage && (
					<>
						<meta
							property="og:image:secure_url"
							content={`https:${selectedStoryImage.url}`}
						/>
						<meta property="og:image" content={`https:${selectedStoryImage.url}`} />
						<meta property="og:image:type" content={selectedStoryImage.contentType} />
						<meta
							property="og:image:width"
							content={selectedStoryImage.details.image.width}
						/>
						<meta
							property="og:image:height"
							content={selectedStoryImage.details.image.height}
						/>
					</>
				)}
				<meta property="og:title" content={title} />
			</Helmet>
			<Swiper
				page={page}
				languageRightToLeft={country && country.languageRightToLeft}
				immediate={isImmediateTransition}
				onChangePage={onChangePage}
			>
				{[
					<Landing key="landing" onClick={() => onChangePage(1)} country={country} />,
					isLoaded && <Introduction key="introduction" country={country} />,
					...stories.map((story, i) => (
						<Card
							key={story.id}
							index={i}
							currentStoryIndex={storyIndex}
							isActive={story === activatedStory}
							isPlaying={story === playingStory}
							story={story}
							onClick={() => onClickStory(story)}
							onClickStart={onClickStart}
							audioRef={el => {
								audioRefs.current[story.id] = el;
							}}
							// position={i < storyIndex ? 'left' : i > storyIndex ? 'right' : 'center'}
						/>
					))
				]}
			</Swiper>
			{country && <Logo small={!!selectedStory} isFarsi={country.languageRightToLeft} />}
			{isLoaded && (
				<StoryInfo
					storyIndex={storyIndex}
					storyCount={stories.length}
					story={selectedStory}
					isActive={selectedStory === activatedStory}
					isPlaying={!!playingStory}
					audioEl={selectedStory && audioRefs.current[selectedStory.id]}
					onClickIndex={index => onClickStory(stories[index])}
					onClickProgress={perc => {
						audioRefs.current[playingStory.id].currentTime =
							perc * audioRefs.current[playingStory.id].duration;
					}}
				/>
			)}
			{page > 0 && <Menu page={page} country={country} />}
		</div>
	);
	// ) : (
	// 	<div className="loading">loading...</div>
	// );
};

export default App;
