/* eslint-disable prefer-destructuring */
import { useState, useEffect } from 'react';

const parseCountry = entry =>
	entry
		? {
				id: entry.sys.id,
				...entry.fields,
				stories: (entry.fields.stories || []).map(story => ({
					id: story.sys.id,
					...story.fields
				}))
		  }
		: null;

export const useCountry = initialCountry => {
	const [country, setCountry] = useState(parseCountry(initialCountry));
	const [countryLoaded, setCountryLoaded] = useState(false);
	const isLocalhost =
		typeof document !== 'undefined' && document.location.hostname.match(/localhost/);
	// This API call will request an entry with the specified ID from the space defined at the top, using a space-specific access token.
	useEffect(() => {
		fetch(`${isLocalhost ? 'http://localhost:5000' : ''}/content`)
			.then(response => response.json())
			.then(entry => {
				setCountry(parseCountry(entry));
				setCountryLoaded(true);
			})
			.catch(err => console.log(err));
	}, []);
	return [country, countryLoaded];
};

export default useCountry;
