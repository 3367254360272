import 'modern-normalize/modern-normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router5';
import 'regenerator-runtime/runtime';
import { HelmetProvider } from 'react-helmet-async';
import App from './components/app/App';
import configureRouter from './router/create-router';

const router = configureRouter();
router.start(() => {
	ReactDOM.render(
		<HelmetProvider>
			<RouterProvider router={router}>
				<App />
			</RouterProvider>
		</HelmetProvider>,
		document.getElementById('app')
	);
});
