import React from 'react';
import RichText from '../RichText';
import './MenuPage.scss';

const MenuPage = ({ content }) => {
	if (!content) return null;

	return (
		<div className="menu-page">
			<RichText text={content} />
		</div>
	);
};

export default MenuPage;
