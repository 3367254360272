import React from 'react';
import LogoEN from '../assets/logo-en.svg';
import LogoFarsi from '../assets/logo-farsi.svg';

import './Logo.scss';

const Logo = ({ small, isFarsi }) => (
	<div className={`logo ${small ? 'logo--small' : ''}`}>
		{isFarsi ? <LogoFarsi /> : <LogoEN />}
	</div>
);

export default Logo;
