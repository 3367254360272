/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { animated, useSpring, config } from 'react-spring/web.cjs';
import { useSwipeable } from 'react-swipeable';
import SwipeLeft from '../../assets/swipe-left.svg';

import './Swiper.scss';

const getSpringProps = (offset, immediate) => ({
	transform: `translate3d(${-offset}px,0px,0px)`,
	immediate
});
const Swiper = ({
	children,
	page,
	onChangePage = Function.prototype,
	immediate,
	languageRightToLeft
}) => {
	const ref = useRef();
	const offsetRef = useRef();
	const maxOffset = useRef(0);

	const [contentSpring, setContentSpring] = useSpring(() => ({
		...getSpringProps(0, true),
		config: { mass: 2, tension: 180, friction: 40 }
	}));

	const getPageOffset = index => {
		const el = ref.current.children[index];
		const isStoryPage = index >= 2;
		const pageDependentOffset = isStoryPage
			? (window.innerWidth - el.offsetWidth) / 2
			: languageRightToLeft
			? window.innerWidth - el.offsetWidth
			: 0;
		const offset = el.offsetLeft - pageDependentOffset;
		return offset;
	};
	const updateSpring = () => {
		const offset = getPageOffset(page);
		offsetRef.current = offset;
		setContentSpring(getSpringProps(offset, immediate));
	};

	useEffect(() => {
		updateSpring();
		window.addEventListener('resize', updateSpring);
		return () => window.removeEventListener('resize', updateSpring);
	}, [page]);

	const setPage = newPage => {
		onChangePage(newPage);
	};

	const onClickPrev = () => {
		if (page > 0) {
			setPage(page - 1);
		}
	};

	const onClickNext = () => {
		if (page < children.length - 1) {
			setPage(page + 1);
		}
	};

	const handlers = useSwipeable({
		onSwiped: e => {
			if (e.dir === 'Left') {
				languageRightToLeft ? onClickPrev() : onClickNext();
			} else if (e.dir === 'Right') {
				languageRightToLeft ? onClickNext() : onClickPrev();
			}
		},
		onSwiping: e => {
			const delta = languageRightToLeft
				? Math.min(0, Math.max(maxOffset.current, offsetRef.current + e.deltaX))
				: Math.max(0, Math.min(maxOffset.current, offsetRef.current + e.deltaX));
			setContentSpring(getSpringProps(delta, false));
		}
	});

	useEffect(() => {
		const offset =
			ref.current && ref.current.children
				? getPageOffset(ref.current.children.length - 1)
				: 0;
		maxOffset.current = offset;
	}, [children]);

	return (
		<div className="swiper" {...handlers}>
			<animated.div className="swiper__content" ref={ref} style={contentSpring}>
				{children}
			</animated.div>
			{page > 0 && (
				<div className="swiper__button swiper__button--prev" onClick={onClickPrev}>
					<SwipeLeft />
				</div>
			)}
			{page < children.length - 1 && (
				<div className="swiper__button swiper__button--next" onClick={onClickNext}>
					<SwipeLeft />
				</div>
			)}
		</div>
	);
};

export default Swiper;
