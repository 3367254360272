import React, { useRef } from 'react';
import './SharePopup.scss';

import { animated, useSpring, useChain } from 'react-spring';
import Whatsapp from '../../assets/whatsapp.svg';
import Email from '../../assets/email.svg';
import Copyurl from '../../assets/copyurl.svg';
import Twitter from '../../assets/twitter.svg';
import Facebook from '../../assets/facebook.svg';
import Telegram from '../../assets/telegram.svg';
import Close from '../../assets/close.svg';
import { openSharePopup, SOCIAL_MEDIA } from '../../hooks/useSharing';

const SHARES = {
	[SOCIAL_MEDIA.whatsapp]: { icon: Whatsapp },
	[SOCIAL_MEDIA.email]: { icon: Email },
	[SOCIAL_MEDIA.copyurl]: { icon: Copyurl },
	[SOCIAL_MEDIA.twitter]: { icon: Twitter },
	[SOCIAL_MEDIA.facebook]: { icon: Facebook },
	[SOCIAL_MEDIA.telegram]: { icon: Telegram }
};
const SharePopup = ({ isOpen, onClose }) => {
	const lightboxSpringRef = useRef();
	const lightboxSpring = useSpring({
		ref: lightboxSpringRef,
		from: {
			opacity: 0,
			pointerEvents: 'none'
		},
		to: {
			opacity: isOpen ? 1 : 0,
			pointerEvents: isOpen ? 'unset' : 'none'
		}
	});

	const popupSpringRef = useRef();
	const popupSpring = useSpring({
		ref: popupSpringRef,
		transform: `translate3d(-50%, ${isOpen ? -50 : -150}%, 0) rotate(${
			isOpen ? 0 : Math.random() * 60 - 30
		}deg)`,
		opacity: isOpen ? 1 : 0,
		config: { mass: 1, tension: 150, friction: 14, precision: 0.001 }
	});

	// This will orchestrate the two animations above, comment the last arg and it creates a sequence
	useChain(isOpen ? [lightboxSpringRef, popupSpringRef] : [popupSpringRef, lightboxSpringRef], [
		0,
		isOpen ? 0.25 : 0.45
	]);

	return (
		<animated.div className="share-popup__lightbox" style={lightboxSpring} onClick={onClose}>
			<animated.div
				className="share-popup"
				style={popupSpring}
				onClick={e => e.stopPropagation()}
			>
				<div className="share-popup__title">Share this story</div>
				{Object.entries(SHARES).map(([name, item]) => {
					const Icon = item.icon;
					return (
						<div
							className="share-popup__item"
							key={name}
							onClick={() =>
								openSharePopup(name, 'sheherazade now', 'hashtag', 'message')
							}
						>
							<div className="share-popup__icon">
								<Icon />
							</div>
							<div className="share-popup__label">{name}</div>
						</div>
					);
				})}
				<div className="share-popup__close" onClick={onClose}>
					<Close />
				</div>
			</animated.div>
		</animated.div>
	);
};

export default SharePopup;
