/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { animated, interpolate, useSpring } from 'react-spring/web.cjs';
import Hand from '../../assets/hand.svg';
import cardBGSrc from '../../assets/postcard-backside-no-lines.jpg';
import Photo from '../Photo';
import Close from '../../assets/close.svg';
import './Card.scss';

const Card = ({
	index,
	currentStoryIndex,
	story,
	onClick,
	audioRef,
	isActive,
	isPlaying,
	onClickStart
}) => {
	const rot = useMemo(() => Math.random() * 6 - 3, []);
	const isCurrent = currentStoryIndex === index;
	const isZoomed = isActive && isCurrent;
	const zoomSpring = useSpring({
		value: isZoomed ? 1 : 0,
		scale: isZoomed ? 1.02 : 1 // scale
	});

	return (
		<div
			className="card"
			style={{ zIndex: isCurrent ? 1 : -index }}
			onClick={onClick}
			data-id={story.id}
		>
			<div className="card__sizer" />
			<animated.div
				style={{
					transform: interpolate(
						[zoomSpring.value, zoomSpring.scale],
						(value, scale) =>
							`translate3d(-50%, -50%, 0) scale(${scale}) perspective(1600px) rotateY(${value *
								180}deg) rotateZ(${rot}deg)`
					)
				}}
				className="card__photo"
			>
				<animated.div
					className="card__front"
					style={{
						visibility: zoomSpring.value.interpolate(value =>
							value < 0.5 ? 'visible' : 'hidden'
						)
					}}
				>
					<Photo story={story} key={story.id} />
					<div className="card__hand">
						<Hand />
					</div>
					<div
						className={classNames({
							card__icon: true,
							'card__icon--playing': isPlaying
						})}
						onClick={e => {
							e.stopPropagation();
							onClickStart();
						}}
					>
						<Close />
					</div>
				</animated.div>
				<animated.div
					className="card__back-side"
					style={{
						visibility: zoomSpring.value.interpolate(value =>
							value > 0.5 ? 'visible' : 'hidden'
						)
					}}
				>
					<div
						className="card__background"
						alt={story.title}
						style={{ backgroundImage: `url('${cardBGSrc}')` }}
					/>
					{story.stamp && (
						<ResponsiveImg
							className="card__stamp"
							src={story.stamp?.fields?.file.url}
							sizes={[1024, 480, 260]}
							defaultSize={480}
							alt="poststamp"
						/>
					)}
					{story.cta && (
						<ResponsiveImg
							className="card__cta"
							src={story.cta?.fields?.file.url}
							sizes={[1024, 640, 360]}
							defaultSize={600}
							alt="CTA"
						/>
					)}
					<div className="card__title">{story.title}</div>
					<div className="card__description">{story.description}</div>
				</animated.div>
			</animated.div>
			{story.audio && (
				<div className="card__audio">
					<audio src={story.audio?.fields.file.url} ref={audioRef}>
						Your browser does not support the
						<code>audio</code> element.
					</audio>
				</div>
			)}
		</div>
	);
};

const ResponsiveImg = ({ className, src, sizes, defaultSize, ...props }) => (
	<picture>
		<source srcSet={sizes.map(size => `${src}?w=${size} ${size}w`).join(', ')} />
		<img className={className} src={`${src}?w=${defaultSize}`} {...props} />
	</picture>
);

export default Card;
